/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { StyledLoader } from './StyledLoader';

const Load = ({ duration, loader }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevCount + 1;
      });
    }, duration / 100);

    return () => clearInterval(interval);
  }, [duration]);

  return (
    <StyledLoader>
      <div className='loader-container'>
        <svg viewBox='0 0 146.9 93.3' xmlns='http://www.w3.org/2000/svg'>
          <g id='triangles' transform='translate(0, 0)'>
            <polygon className='triangle' points='0,93.3 20,0 43.3,93.3' />
            <polygon className='triangle' points='0,93.3 31.5,9 61.5,93.3' />
            <polygon className='triangle' points='0,93.3 45.4,26.1 78.8,93.3' />
            <polygon className='triangle' points='0,93.3 61.5,43.9 99.4,93.3' />
            <polygon
              className='triangle'
              points='0,93.3 77.8,56.2 118.6,93.3'
            />
            <polygon className='triangle' points='0,93.3 100,67 133.6,93.4' />
            <polygon
              className='triangle'
              points='0,93.3 119.4,77.2 146.6,93.4'
            />
          </g>
        </svg>
        <div className='counter'>{loader === 'app' && <p>{count}</p>}</div>
      </div>
    </StyledLoader>
  );
};

export default Load;
