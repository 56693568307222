import { useState, useEffect, lazy, Suspense } from 'react';
import GlobalStyle from './components/styles/GlobalStyle.js';
import { Cursor, Color } from './components/shared/ui/CustomCursor.js';
import { ThemeProvider } from './components/context/ThemeContext.jsx';
import Header from './components/shared/Header/index.jsx';
import Loader from './components/shared/Loader/index.jsx';

const Portfolio = lazy(() => import('./components/pages/Portfolio'));

const App = () => {
  const [cursor1Position, setCursor1Position] = useState({ x: 0, y: 0 });
  const [cursor2Position, setCursor2Position] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursor1Position({ x: e.clientX, y: e.clientY });
      setTimeout(() => {
        setCursor2Position({ x: e.clientX, y: e.clientY });
      }, 100);
    };
    document.addEventListener('mousemove', handleMouseMove);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      return () => clearTimeout(timer);
    };
  }, []);

  const activeCursor = () => {
    const cursor2 = document.querySelector('.cursor:nth-child(2)');
    if (cursor2) {
      cursor2.style.width = '100px';
      cursor2.style.height = '100px';
    }
  };

  const inactiveCursor = () => {
    const cursor2 = document.querySelector('.cursor:nth-child(2)');
    if (cursor2) {
      cursor2.style.width = '22px';
      cursor2.style.height = '22px';
    }
  };

  return (
    <ThemeProvider>
      <>
        <GlobalStyle />
        {isLoading ? (
          <Loader duration='2500' loader='app' />
        ) : (
          <>
            <Cursor
              className='cursor'
              style={{
                left: `${cursor1Position.x}px`,
                top: `${cursor1Position.y}px`,
              }}
            />
            <Cursor
              className='cursor'
              style={{
                left: `${cursor2Position.x}px`,
                top: `${cursor2Position.y}px`,
              }}
            />
            <Color
              onMouseOut={inactiveCursor}
              onMouseOver={activeCursor}
              onClick={inactiveCursor}
            >
              <div className='header'>
                <Header />
              </div>
              <Suspense fallback={<Loader />}>
                <Portfolio />
              </Suspense>
            </Color>
          </>
        )}
      </>
    </ThemeProvider>
  );
};

export default App;
