import { useI18n } from '../../context/i18nContext';
import { StyledHeader } from './StyledHeader';

function Header() {
  const { changeLanguage } = useI18n();

  return (
    <StyledHeader>
      <div className='logo-box'>
        <a href='#home'>
          <img src='/mmonter_logo_clear.svg' alt='logo' className='logo' />
        </a>
      </div>
      <div className='language'>
        <p className='lng en' onClick={() => changeLanguage('en')}>
          En
        </p>
        <p className='lng es' onClick={() => changeLanguage('es')}>
          Es
        </p>
      </div>
    </StyledHeader>
  );
}

export default Header;
