import styled from 'styled-components';
import { setFlex } from '../../styles/utils';

export const StyledHeader = styled.div`
  ${setFlex({ x: 'space-between' })};
  position: fixed;
  top: 1rem;
  width: 100vw;
  height: 7rem;
  color: #fff;
  mix-blend-mode: difference !important;
  z-index: 25;
  padding: 0;
  margin: 0;

  .logo-box {
    position: relative;
    margin-top: 1rem;
    margin-left: 2rem;
    width: 6rem;
    height: 6rem;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .language {
    width: 7rem;
    ${setFlex({ x: 'space-around' })};
    margin-right: 1rem;

    p {
      font-size: 1.8rem;
      text-align: right;
      font-weight: 300;
    }
  }
`;
