import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html { 
    font-size: 62.5%;
    cursor: none;
  }

  body {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 1.6rem;
    cursor: none;
    /* overflow-x: hidden;
    overflow-y: scroll; */
  }  
`;

export default GlobalStyle;
