import styled from 'styled-components';
import { below, colors } from '../../styles/utils';

export const StyledLoader = styled.div`
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    z-index: 9999;
  }
  svg {
    width: 250px;
    height: auto;
  }
  .triangle {
    fill: none;
    stroke: #ffffff34;
    stroke-width: 0.5;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: drawAndFill 2s ease-in-out infinite alternate;
  }
  .triangle:nth-child(2) {
    animation-delay: 0.1s;
  }
  .triangle:nth-child(3) {
    animation-delay: 0.2s;
  }
  .triangle:nth-child(4) {
    animation-delay: 0.3s;
  }
  .triangle:nth-child(5) {
    animation-delay: 0.4s;
  }
  .triangle:nth-child(6) {
    animation-delay: 0.5s;
  }
  .triangle:nth-child(7) {
    animation-delay: 0.6s;
  }
  @keyframes drawAndFill {
    0% {
      stroke-dashoffset: 300;
      fill: transparent;
    }
    50% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 0;
      fill: #ffffff7d;
    }
  }

  .counter {
    width: 100%;
    margin-top: 5rem;

    p {
      position: absolute;
      bottom: 2rem;
      right: 3rem;
      font-family: 'Pixelify Sans', sans-serif;
      color: ${colors.white};
      font-size: clamp(7em, 7vw, 7em);
    }
  }

  ${below.md`
     .counter {
      width: 100%;
      margin-top: 5rem;

      p {
        position: absolute;
        bottom: 8rem;
        right: 2.5rem;
        font-size: clamp(5em, 5vw, 5em);
      }
    }
  `};
`;
