/* eslint-disable react-refresh/only-export-components */
import { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../styles/utils';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const appColors = [
    colors.app_color_1,
    colors.app_color_2,
    colors.app_color_3,
    colors.app_color_4,
    colors.app_color_5,
    colors.app_color_6,
    colors.app_color_7,
  ];
  const [appColor] = useState(
    () => appColors[Math.floor(Math.random() * appColors.length)]
  );
  return (
    <ThemeContext.Provider value={{ appColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
