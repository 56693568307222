import styled from 'styled-components';

export const Bg = styled.div`
  /* width: 100vw; */
  /* height: 100vh; */
  /* height: 100%; */
  /* background: rgb(231, 243, 234); */
  display: block;
  position: relative;
`;

export const Cursor = styled.div`
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  mix-blend-mode: difference !important;
  transition: width 400ms ease, height 400ms ease;

  &:nth-child(1) {
    background-color: #ffffff;
    height: 8px;
    width: 8px;
  }

  &:nth-child(2) {
    background-color: #fefefe;
    height: 22px;
    width: 22px;
  }
`;

export const Color = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  mix-blend-mode: difference !important;
  transition: width 400ms ease, height 400ms ease;
`;
