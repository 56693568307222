import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { I18nProvider } from './components/context/i18nContext.jsx';

'serviceWorker' in navigator &&
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <I18nProvider>
      <App />
    </I18nProvider>
  </React.StrictMode>
);
