export const colors = {
  w_2: '#EBEDF2',
  gray: '#BFBFBF',
  dark: '#202326',
  blue: '#056CF2',
  pink: '#F29494',
  rosa: '#F50087',
  green: '#127369',
  black: '#000000',
  white: '#f1f2f6',
  yellow: '#FFAE00',
  purple: '#614BF2',
  trap_4: '#6E7173',
  app_color_1: '#F50087',
  app_color_2: '#BFB9FF',
  app_color_3: '#FF4858',
  app_color_4: '#056CF2',
  app_color_5: '#04BF9D',
  app_color_6: '#F2B705',
  app_color_7: '#8C8C8C',
  // app_color_7: '#B9EE02',
};
